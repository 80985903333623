import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Chatbot.css'; 
import logo from './images/logo.svg'; 

function Chatbot() {
  const { interviewId } = useParams();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [interviewOver, setInterviewOver] = useState(false);
  const inputRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const fetchHistory = async () => {
      console.log('Fetching past conversations for interviewId:', interviewId);
      try {
        const storedSessionId = localStorage.getItem(`session_${interviewId}`);
        let newSessionId = storedSessionId;
        if (storedSessionId) {
          setSessionId(storedSessionId);
          console.log('Session ID retrieved from localStorage:', storedSessionId);
        } else {
          newSessionId = Date.now().toString();
          setSessionId(newSessionId);
          localStorage.setItem(`session_${interviewId}`, newSessionId);
          console.log('New session ID created and stored:', newSessionId);
        }

        if (newSessionId) {
          const historyResponse = await axios.get(`/api/chat/${interviewId}/history`, {
            params: { sessionId: newSessionId }
          });
          setMessages(historyResponse.data.pastConversations);
          setInterviewOver(historyResponse.data.interviewOver);
          console.log('Past conversations fetched:', historyResponse.data.pastConversations);
          //console.log('Interview over status:', historyResponse.data.interviewOver);
        }

      } catch (error) {
        console.error('Error fetching past conversations:', error);
      }
    };
    fetchHistory();
  }, [interviewId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    if (!input.trim()) {
      console.warn('Attempted to send an empty message');
      return;
    }
    console.log('Sending message:', input);
    try {
      const response = await axios.post(`/api/chat/${interviewId}`, { 
        message: input, 
        sessionId: sessionId 
      });
      console.log('Received bot response:', response.data.response);
      
      setMessages(response.data.pastConversations);
      localStorage.setItem(`messages_${sessionId}`, JSON.stringify(response.data.pastConversations));
      console.log('Messages updated and stored:', response.data.pastConversations);

      setInput('');
      setInterviewOver(response.data.interviewOver);
      if (!response.data.interviewOver) {
        inputRef.current.focus();
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="chatbot-container">
      <header className="chatbot-header">
        <img src={logo} alt="Logo" className="chatbot-logo" />
      </header>
      <div className="chat-instructions">
        <p>Por favor, inicia la conversación con un saludo incluyendo tu nombre para que el bot pueda reconocerte.</p>
        <p>Ejemplo: "Hola, soy [Tu Nombre]"</p>
      </div>
      <div className="chat-history" ref={chatContainerRef}>
        {messages.map((msg, index) => (
          <div key={index} className="chat-message">
            {msg.user && <p><strong>User:</strong> {msg.user}</p>}
            {msg.bot && <p><strong>Bot:</strong> {msg.bot}</p>}
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <textarea
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          className="chat-input"
          disabled={interviewOver}
        />
        <button
          onClick={sendMessage}
          className={`send-button ${interviewOver || !input.trim() ? 'disabled' : ''}`}
          disabled={interviewOver || !input.trim()}
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Chatbot;
