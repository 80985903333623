import React, { useState } from 'react';
import axios from 'axios';
import styles from './upload.module.css';
import logo from './images/logo.svg'; // Ensure the path is correct

function JobProfileUpload() {
    const [questions, setQuestions] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState('');
    const [email, setEmail] = useState('');
    const [file, setFile] = useState(null);
    const [instructions, setInstructions] = useState('');
    const [generatedLink, setGeneratedLink] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`/api/login`, { email, password });
            const token = response.data.token;
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
            alert('Inicio de sesión exitoso');
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            alert('Error al iniciar sesión');
        }
    };

    const handleUpload = (event) => {
        if (!isAuthenticated) {
            alert('Debe iniciar sesión para subir archivos.');
            return;
        }
        setFile(event.target.files[0]);
    };

    const handleGenerateQuestions = async () => {
        if (!isAuthenticated) {
            alert('Debe iniciar sesión para generar preguntas.');
            return;
        }
        if (!file) {
            console.error('No se ha seleccionado ningún archivo');
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('profile', file);
        formData.append('instructions', instructions);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`/api/upload-pdf`, formData, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
            setGeneratedLink('');
            setQuestions(response.data.questions);
            console.log('Preguntas generadas:', response.data.questions);
        } catch (error) {
            console.error('Error al generar preguntas:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const reloadQuestionsFromFile = async (uniqueId) => {
        try {
            const response = await axios.get(`/api/questions/${uniqueId}`);
            const fileQuestions = response.data.questions;
            setQuestions(fileQuestions);
            console.log('Questions reloaded from file:', fileQuestions);
        } catch (error) {
            console.error('Error reloading questions from file:', error);
        }
    };

    const [linkGenerated, setLinkGenerated] = useState(false);

    const handleGenerateLink = async () => {
        if (!questions.length || !email) {
            alert('Se requieren preguntas y correo electrónico para generar un enlace.');
            console.error('Se requieren preguntas y correo electrónico');
            return;
        }
        try {
            const response = await axios.post(`/api/store-questions`, { questions, email });
            const uniqueId = response.data.uniqueId;
            const link = `${window.location.origin}/chatbot/${uniqueId}`;
            setGeneratedLink(link);

            console.log('Enlace generado:', link);
            reloadQuestionsFromFile(uniqueId);
        } catch (error) {
            console.error('Error al generar el enlace:', error);
        }
    };

    const handleEdit = (index) => {
        //console.log(`Editing question at index: ${index}`);
        setEditingIndex(index);
        setEditingQuestion(questions[index]); // Load the question into the editor
    };

    const handleSave = (index) => {
        //console.log(`Saving edited question at index: ${index}`);
        const updatedQuestions = [...questions];
        updatedQuestions[index] = editingQuestion;
        setQuestions(updatedQuestions);
        setEditingIndex(null); // Clear editing state
        setEditingQuestion(''); // Clear the input state
    };

    const handleDelete = (index) => {
        //console.log(`Deleting question at index: ${index}`);
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };

    const handleCopyLink = () => {
        if (generatedLink) {
            navigator.clipboard.writeText(generatedLink)
                .then(() => {
                    console.log('Enlace copiado al portapapeles');
                })
                .catch((error) => {
                    console.error('Error al copiar el enlace:', error);
                });
        }
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </header>

            {isAuthenticated ? (
                <>
                    <h2>Carga de Perfil de Puesto y Generación de Preguntas</h2>
                    <p>Siga los pasos a continuación para cargar un PDF con el perfil del puesto y generar preguntas de entrevista:</p>
                    <ol>
                        <li>Haga clic en el botón "Elegir archivo" para seleccionar un archivo PDF que contenga la descripción del perfil del puesto.</li>
                        <li>Haga clic en el botón "Generar Preguntas" para crear una lista de preguntas de entrevista basadas en el contenido del PDF.</li>
                        <li>Puede editar cualquier pregunta haciendo clic en el botón "Editar" junto a ella. Use el editor para realizar cambios y haga clic en "Guardar" para actualizar la pregunta.</li>
                        <li>Ingrese su dirección de correo electrónico en el campo proporcionado para recibir las respuestas del candidato.</li>
                        <li>Una vez que las preguntas estén listas, haga clic en "Generar Link" para crear un enlace que pueda enviar al aplicante.</li>
                    </ol>
                    <input type="file" onChange={handleUpload} className={styles.fileInput} />
                    <textarea 
                        value={instructions} 
                        onChange={(e) => setInstructions(e.target.value)} 
                        placeholder="Ingrese instrucciones detalladas para la IA" 
                        className={styles.instructionsInput} 
                    />
                    <button onClick={handleGenerateQuestions} className={styles.saveButton}>Generar Preguntas</button>
                    {isLoading && <p>Generando preguntas, por favor espere...</p>}
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Ingrese correo electrónico" className={styles.emailInput} />
                    <button onClick={handleGenerateLink} className={styles.saveButton}>Generar Link</button>
                    {generatedLink && (
                        <div>
                            <p>Enlace Generado: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a></p>
                            <button onClick={handleCopyLink} className={styles.copyButton}>Copiar Link</button>
                        </div>
                    )}
                    <ul className={styles.questionList}>
                        {questions.map((question, index) => (
                            <li key={index} className={styles.questionItem}>
                                {editingIndex === index ? (
                                    <>
                                        <textarea 
                                            value={editingQuestion} 
                                            onChange={(e) => setEditingQuestion(e.target.value)} 
                                            className={styles.editor} 
                                        />
                                        <button onClick={() => handleSave(index)} className={styles.saveButton}>Guardar</button>
                                    </>
                                ) : (
                                    <>
                                        <p>{question}</p>
                                        <button onClick={() => handleEdit(index)} className={styles.editButton}>Editar</button>
                                        <button onClick={() => handleDelete(index)} className={styles.deleteButton}>Eliminar</button>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <form onSubmit={handleLogin} className={styles.loginForm}>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Correo electrónico" 
                        required 
                    />
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Contraseña" 
                        required 
                    />
                    <button type="submit" className={styles.loginButton}>Iniciar Sesión</button>
                </form>
            )}
            <footer className={styles.footer}>
                <p>© 2024 Manpalider S.A. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
}

export default JobProfileUpload;
